import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { getCurrentInstance } from 'vue';
import { useTranslate } from '@/modules/core/compositions/translate';

export const useCreateProduct = () => {
  const { $message } = getCurrentInstance().proxy;
  const $t = useTranslate();

  const { mutate, onError, onDone, loading } = useMutation(CREATE_PRODUCT);

  onError((err) => {
    $message.error($t('errors.action'));
    console.error('useCreateProduct', err);
  });

  return {
    createProduct: mutate,
    onError,
    onDone,
    loading,
  };
};

const CREATE_PRODUCT = gql`
  mutation productCreateNew($params: ProductInputNew!) {
    productCreateNew(params: $params) {
      id
      name
      sku
    }
  }
`;
